.footer__box {
    width: 100%;
    height: 7.3rem;
    background: rgba(66,66,66,0.09%);
}

.footer__text {
    padding: 0;
    margin: 0;
    color: $gray;
    font-size: 1.15rem;
    width: 100%;
    text-align: center;
    padding-top: 0.55rem;
}

.footer__link {
    color: $gray;
}

.footer__link:hover {
    color: $gray;
    text-decoration: none;
}


@media (max-width: $mobileMaxWidth) {

    .footer__box {
        height: 7.6rem;
    }

    .footer__text {
        font-size: 1.9rem;
        padding-top: 1.4rem;
        line-height: 2.5rem;
    }

}