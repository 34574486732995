@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import "../../node_modules/slick-carousel/slick/slick.css";

@import './base/settings';
@import './base/base';

@import './components/AboutPage';
@import './components/Backoffice';
@import './components/CustomersStrip';
@import './components/ContactStrip';
@import './components/ContactPage';
@import './components/EventsPage';
@import './components/EventPage';
@import './components/Footer';
@import './components/HomePage';
@import './components/NotFoundPage';
@import './components/Navigation';
@import './components/PageUpStrip';
@import './components/WorkshopPage';

@import './base/fixers';

@font-face {
  font-family: almoni-tzar-aaa-regular;
  src: url(/assets/fonts/almoni-tzar-aaa-regular.otf) format('truetype');
}