.contactpage__structure {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50rem;
    margin-top: -5rem;
    background-image: url('https://res.cloudinary.com/dz7woxmn2/image/upload/v1542219897/contact_bg_1.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 0;
}

.contactpage__container {
    width: 100vw;
}

.contact__form__box--page {
    width: 90rem;
    margin-top: 38vw;
    margin-bottom: 5.1rem;
    display: block;
    text-align: center;
}

@media (min-width: 1300px) {
    .contact__form__box--page {
        margin-top: 38.5vw;
    }
}

@media (min-width: 1350px) {
    .contact__form__box--page {
        margin-top: 39vw;
    }
}

@media (min-width: 1400px) {
    .contact__form__box--page {
        margin-top: 40vw;
    }
}

.contact__form__header--page {
    width: 90rem;
    background: $yellow;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    padding-top: 2.3rem;
    padding-bottom: 0.7rem;
    font-size: 4.2rem;
    letter-spacing: -0.08rem;
    color: $white;
}

.contact__form--page {
    position: relative;
    width: 90rem;
    height: 17rem;
    background: $yellow;
    display: flex;
    justify-content: center;
}

.contact__error--page {
    font-size: 1.45rem;
    color: $white;
}

.contact__form--page input { 
    display: block;
    font-size: 1.45rem;
    color: $gray;
    width: 37rem;
    height: 3.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: $font;
    font-weight: $fontRegular;
    border: none;
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
}

.contact__form__eng--page input[type="email"] {
    text-align: right;
}
.contact__form__eng--page input[type="email"]:-moz-placeholder {
    text-align: left;
}
.contact__form__eng--page input[type="email"]:-ms-input-placeholder {
    text-align: left;
}
.contact__form__eng--page input[type="email"]::-webkit-input-placeholder {
    text-align: left;
}

.contact__form--page textarea { 
    display: block;
    font-size: 1.45rem;
    color: $gray;
    width: 32.2rem;
    height: 10.8rem;
    padding-top: 0.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: $font;
    font-weight: $fontRegular;
    border: none;
    margin-left: 0.3rem;
    margin-bottom: 0.8rem;
    resize: none;
}

.contact__button--page {
    display: none;
}



.contact__imagebutton--page {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    align-content: center;
    background: none;
    border: none;
    position: absolute;
    left: 9.6rem;
    margin-top: -2.3rem;
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1);
    transition: all 20ms ease-in;
    transform: scale(1);   
}

.contact__imagebutton--page:hover
{
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1.2);
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1.2);   
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1.2);
    transition: all 20ms ease-in;
    transform: scale(1.2);
}





.contact__imagebutton__image {
    width: 2.2rem;
    padding-top: 1.3rem;
}

.contact__imagebutton__text {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    margin-left: 0.3rem;
    padding-top: 0.5rem;
    color: $white;
}


//

.contact__follow__box--page {
    width: 90rem;
    display: block;
    text-align: center;
    margin-bottom: 7rem;
}

.contact__follow__header--page {
    width: 90rem;
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    padding-bottom: 0.2rem;
    font-size: 4.2rem;
    letter-spacing: -0.08rem;
    color: $green;
}

.contact__follow__facebook__icon--page {
    display: inline-block;
    width: 4.8rem;
    height: 4.9rem;
    background-image: url('/images/contact/facebook-icon.svg');
    background-repeat: no-repeat;
    background-size: 4.8rem 4.9rem;
    margin-top: -0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    cursor: pointer;
}

.contact__follow__facebook__icon--page:hover {
    background-image: url('/images/contact/facebook-icon-over.svg');
}

.contact__follow__facebook__icon--page__hover {
    background-image: url('/images/contact/facebook-icon-over.svg');
    position: absolute;
    z-index: 7002;
    opacity: 0;
}

.contact__follow__instagram__icon--page {
    display: inline-block;
    width: 4.8rem;
    height: 4.9rem;
    background-image: url('/images/contact/instagram-icon.svg');
    background-repeat: no-repeat;
    background-size: 4.8rem 4.9rem;
    margin-top: -0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    cursor: pointer;
}

.contact__follow__instagram__icon--page:hover {
    background-image: url('/images/contact/instagram-icon-over.svg');
}

.contact__follow__instagram__icon--page__hover {
    background-image: url('/images/contact/instagram-icon-over.svg');
    position: absolute;
    z-index: 7003;
    opacity: 0;
}

//

.contact__contact__box--page {
    width: 90rem;
    display: inline-block;
    text-align: center;
}

.contact__contact__header--page {
    width: 90rem;
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: 4.2rem;
    letter-spacing: -0.08rem;
    color: $green;
    margin-bottom: 1rem;
}

.contact__confirm__seperator {
    width: 20vw;
}

.contact__contact__text--page {
    display: inline-block;
    margin: 0;
    padding: 0;
    color: $gray;
    font-size: 3rem;
    letter-spacing: -0.08rem;
    text-align: center;
    margin-top: -1rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    cursor: pointer;
}

.contact__seperator--page {
    width: 32rem;
    margin-top: -0.8rem;
}


.contact__confirm__image {
    width: 15vw;
    margin-top: 4rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.contact__modal__close__button {
    display: none;
}

.contact__modal__button__image__x {
    width: 2.6rem;
    padding-top: 0.3rem;
    margin-right: 0rem;
}

.contact__modal__container {
    width: 28vw;
    text-align: center;
}

.contact__confirm__header {
    font-size: 2.4rem;
    color: $gray;
    margin-bottom: 0rem;
}

.contact__confirm__text {
    font-size: 1.8rem;
    color: $gray;
    margin-bottom: 0rem;
}

.contact__confirm__footer {
    font-size: 2.8rem;
    color: $yellow;
    margin-bottom: 1rem;
}







@media (max-width: $tabletMaxWidth) {
    .contactpage__container {
        width: 72rem;
    }

    .contact__form__box--page {
        width: 72rem;
    }

    .contact__form__header--page {
        width: 72rem;
    }

    .contact__form--page {
        width: 72rem;
    }

    .contact__follow__box--page {
        width: 72rem;
    }

    .contact__follow__header--page {
        width: 72rem;
    }

    .contact__contact__box--page {
        width: 72rem;
    }

    .contact__contact__header--page {
        width: 72rem;
    }

    .contact__form--page input {
        width: 34rem;
    }

    .contact__imagebutton--page {
        left: 3.6rem;
    }
}







@media (max-width: $mobileMaxWidth) {
    .contactpage__structure {
        padding-top: 26rem;
        margin-top: 0rem;
        background-image: url('https://res.cloudinary.com/dz7woxmn2/image/upload/v1542319956/contact_bg_1_mobile.jpg');
    }
    .contactpage__container {
        width: 100%;
    }

    .contact__form__box--page {
        width: 100%;
        margin-top: 47vw;
        margin-bottom: 2.5rem;
    }

    .contact__form__header--page {
        width: 94%;
        margin: 0;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2.3rem;
        padding-bottom: 0.7rem;
        font-size: 5.5rem;
        letter-spacing: -0.08rem;
    }

    .contact__form--page {
        width: 94%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .contact__form--page input { 
        font-size: 3.6rem;
        width: 88vw;
        height: 18vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.9vw;
    }

    .contact__form--page textarea { 
        font-size: 3.6rem;
        width: 88vw;
        height: 73vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2.1vw;
    }

    .contact__button__mobile--page {
        width: 88vw;
        height: 18vw;
        font-size: 4.2rem;
        background: $gray;
        color: $white;
        border: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vw;
    }

//////////////////////////////////////


    .contact__contact__box--page {
        width: 100%;
    }

    .contact__contact__header--page {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        font-size: 5.6rem;
        letter-spacing: -0.08rem;
        margin-bottom: 0;
    }

    .contact__contact__text__line--page {
        margin-top: 0.8rem;
    }

    .contact__contact__text--page {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 3.8rem;
        letter-spacing: -0.08rem;
        margin-top: -1rem;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
    }





//////////////////////////////////////

    .contact__follow__box--page {
        width: 100%;
        margin-bottom: 4.5rem;
    }

    .contact__follow__header--page {
        width: 100%;
        margin: 0;
        padding: 0;
        margin-top: -0.5rem;
        margin-bottom: 1rem;
        padding-bottom: 0.2rem;
        font-size: 5.6rem;
        letter-spacing: -0.08rem;
    }

    $cointactIconMobileWidth: 7.1rem;
    $cointactIconMobileHeight: 7.3rem;

    .contact__follow__facebook__icon--page {
        display: inline-block;
        width: $cointactIconMobileWidth;
        height: $cointactIconMobileHeight;
        background-image: url('/images/contact/facebook-icon.svg');
        background-repeat: no-repeat;
        background-size: $cointactIconMobileWidth $cointactIconMobileHeight;
        margin-top: -0.8rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .contact__follow__instagram__icon--page {
        display: inline-block;
        width: $cointactIconMobileWidth;
        height: $cointactIconMobileHeight;
        background-image: url('/images/contact/instagram-icon.svg');
        background-repeat: no-repeat;
        background-size: $cointactIconMobileWidth $cointactIconMobileHeight;
        margin-top: -0.8rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

////////////////////////////////

    .contact__seperator--page {
        width: 30rem;
        margin-top: -0.8rem;
    }

    .contact__imagebutton--page {
        align-self: center;
        position: relative;
        margin-top: 0;  
    }
}


