.event__left {
    text-align: center;
}



.event__navigation__box {
    width: 20vw;
    display: block;
    
}

.events__eventshare__nav {
    display: block;
    width: 18vw;
}

.event__container {
    display: flex;
    justify-content: center;
    align-items: center;
}


.event__sharestrip__box {
    width: 20vw;
    display: block;
    margin-top: 2rem;
}

.events__eventshare__share {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 20vw;
    padding-left: 3rem;
    padding-right: 3rem;
}




$iconWidth: 2.4rem;
$iconHeight: 2.4rem;
$iconMarginTop: 0.4rem;
$iconMarginBottom: 0.6rem;

.events__eventshare__phone {
    width: $iconWidth;
    height: $iconHeight;
    background-image: url('/images/eventspage/whatsapp.png');
    background-repeat: no-repeat;
    background-size: $iconWidth $iconHeight;
    margin-top: $iconMarginTop;
    margin-bottom: $iconMarginBottom;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.events__eventshare__phone:hover {
    background-image: url('/images/eventspage/whatsapp_over.png');
}

.events__eventshare__mail {
    width: $iconWidth;
    height: $iconHeight;
    background-image: url('/images/eventspage/email.png');
    background-repeat: no-repeat;
    background-size: $iconWidth $iconHeight;
    margin-top: $iconMarginTop;
    margin-bottom: $iconMarginBottom;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.events__eventshare__mail:hover {
    background-image: url('/images/eventspage/email_over.png');
}

.events__eventshare__facebook {
    width: $iconWidth;
    height: $iconHeight;
    background-image: url('/images/eventspage/facebook.png');
    background-repeat: no-repeat;
    background-size: $iconWidth $iconHeight;
    margin-top: $iconMarginTop;
    margin-bottom: $iconMarginBottom;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.events__eventshare__facebook:hover {
    background-image: url('/images/eventspage/facebook_over.png');
}

.events__eventshare__text {
    align-self: center;
    padding-top: 1rem;
    margin: 0;
    margin-left: 2rem;
    margin-right: 0rem;
    color: $gray;
    font-size: 1.3rem;
    line-height: 1.3rem;
}

.events__eventshare__text__eng {
    margin-left: 0rem;
    margin-right: 2rem;
}

.events__eventshare__button__box {
    display: inline-block;
    width: 12vw;
}

.events__eventshare__button__box__x {
    display: inline-block;
    width: 2vw;
    margin-left: 3rem;
    margin-right: 3rem;
}

.events__eventshare__button__text {
    margin: 0;
    padding: 0;
    font-size: 1.45rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    padding-top: 0.6rem;
    padding-bottom: 0rem;
    color: $gray;
}

.events__eventshare__more__button__image {
    width: 1.65rem;
    padding-top: 1.8rem;
}

.events__eventshare__more__button__text {
    margin: 0;
    padding: 0;
    font-size: 1.45rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    margin-top: 1.2rem;
    margin-bottom: 0.8rem;
    color: $gray;
}

.events__eventshare__button__image {
    width: 1.65rem;
    margin-top: 1.2rem;
}

@media all and (-ms-high-contrast:none){
    .events__eventshare__button__image {
        margin-top: 0.6rem;
    }
     *::-ms-backdrop, .events__eventshare__button__image {
        margin-top: 0.6rem;
    }
}

/*----MS Edge Browser CSS Start----*/
@supports ( position:-ms-page ) {
    .events__eventshare__button__image {
        margin-top: 0.6rem;
    }
}
/*----MS Edge Browser CSS End----*/

.events__eventshare__button__image__x {
    width: 1.35rem;
    padding-top: 1.1rem;
    margin-left: 1rem;
}

.events__eventshare__button {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    background: none;
    border: none;
    position: relative;
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1);
    transition: all 20ms ease-in;
    transform: scale(1);   
}

.events__event__carousel__button__image {
    width: 2.6rem;
    _margin-top: 1.6rem;
    margin-left: 1rem;
}

.events__event__carousel__button__image__fake {
    opacity: 0;
}

.events__event__carousel__button__image__x {
    width: 1.7rem;
    _padding-top: 1.6rem;
    margin-left: 1rem;
}

.events__event__carousel__button {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    background: none;
    border: none;
    position: relative;
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1);
    transition: all 20ms ease-in;
    transform: scale(1);   
}

.events__eventshare__button:hover {
    text-decoration: none;
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1.1);   
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 20ms ease-in;
    transform: scale(1.1);
}





.event__header__box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-top: 5rem;
    padding-bottom: 0.4rem;
    
}

.event__header__in__box {
    text-align: right;
    padding-right: 3rem;
    padding-left: 0;
    float: right;
}

.event__header__in__box__eng {
    text-align: left;
    padding-right: 0;
    padding-left: 3rem;
    float: left;
}

.event__header__image__box {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    _width: 100%;
    _height: 100%;
    margin: 0;
    margin-top: auto;
    padding: 0;
    padding-top: auto;
    margin-bottom: 5rem;
}

.event__header__image {
    max-width: 100%;
    max-height: 100%;
    align-self: center;
    flex: 0 0 auto;
}

.event__header__content__box {
    position: relative;
    display: inline-block;
    _width: 46%;
    margin: 0;
    padding: 0;
}

.event__header__box__all {
    width: 100%;
}



.event__header__box input {
    font-family: $font;
    font-weight: $fontRegular;
    color: $gray;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
}


.event__header {
    display: inline-block;
    color: $gray;
    font-size: 2.8rem;
    margin: 0;
    padding: 0;
    padding-top: 5rem;
    border-bottom: 1px solid $yellow;
}

.event__header__input {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
    padding: 0;
    padding-top: 1rem;
}

.event__header__seperator {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.1rem;
    margin-bottom: 0.6rem;
}

.events__seperator__midget {
    margin-bottom: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.event__text {
    color: $gray;
    font-size: 1.3rem;
    line-height: 1.3rem;
    margin: 0;
    padding: 0;
}


$eventsEventWidth: 25rem;
$eventsEventHeight: 30rem;
$eventsEventImageHeight: 25rem;


.events__event__box {
    position: relative;
    width: $eventsEventWidth;
    margin-top: 2.2rem;
    margin-left: 1.1rem;
    margin-right: 1rem;
    padding-bottom: 1rem;
    background: $white;
    cursor: pointer;
}

.events__event__image__box{
    width: $eventsEventWidth;
    height: $eventsEventImageHeight;
    display: block;
}



.events__event__image {
    max-width: $eventsEventWidth;
    max-height: $eventsEventImageHeight;
}

.events__event__title {
    width: $eventsEventWidth;
    color: $white;
    font-size: 1.4rem;
    padding-top: 0.8rem;
    background-color: $green;
    height: 3.3rem;
}

.events__event__add__button {
    width: $eventsEventWidth;
    height: $eventsEventImageHeight;
    margin: 0;
    padding: 0;
    border: none;
    background-color: $green;
}


.events__event__button__box {
    width: $eventsEventWidth;
    height: $eventsEventHeight;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    overflow: hidden;
    cursor: pointer;
}

.events__event__button {
    background: none;
    border: none;
    width: $eventsEventWidth;
    height: $eventsEventHeight;
    position: absolute;
    _top: $eventsEventHeight;
    top: 0;
    left: 0;
    z-index: 15;
    background-color: rgba(79, 179, 162, 0.68);
    opacity: 0.25;
    -webkit-transition: all $transitionTime ease-in; /* Safari */
    transition: all $transitionTime ease-in;
    margin: 0;
    padding: 0;
}

.events__event__button__box:hover button {
    opacity: 1;
}

.events__event__button__text {
    color: $white;
    font-size: 3rem;
    line-height: 2.8rem;
    margin: 0;
    padding: 0;
}

.events__event__button__text__div {
    width: $eventsEventWidth;
    height: $eventsEventHeight;
    background: none;
    position: absolute;
    top: $eventsEventHeight;
    z-index: 16;
    -webkit-transition: all $transitionTime ease-in; /* Safari */
    transition: all $transitionTime ease-in;
}

.events__event__button__box:hover div {
    top: 4.5rem;
}

.events__event__button__image {
    width: 4.5rem;
}



///


.events__events__oneline__header__box {
    text-align: right;
    padding-right: 1rem;
}

.events__events__oneline__more {
    margin: 0;
    padding: 0;
    padding-top: 0.6rem;
    padding-bottom: 0.3rem;
    font-size: 1.5rem;
    color: $gray;
}

.events__events__oneline__header {
    font-size: 1.9rem;
    color: $yellow;
    padding-top: 0.8rem;
}


.events__event__box--event {
    margin-top: 0.7rem;
}



.event__text__box {
    position: relative;
    display: block;
    width: 30rem;
    height: 50vh;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
    text-align: right;;
}

.event__text__box__eng {
    text-align: left;
}

.event__text__box input {
    font-family: $font;
    font-weight: $fontRegular;
    width: 5rem;
    height: 2rem;
    background: none;
    border: 1px solid $gray;
}

.event__text__box__input {
    display: inline-block;
    position: absolute;
    width: 5rem;
    height: 2rem;
    z-index: 5;
    left: -10%;
    top: 4%;
}

.event__text__box textarea {
    font-family: $font;
    font-weight: $fontRegular;
    display: inline-block;
    text-align: right;
    color: $gray;
    font-size: 1.6rem;
    line-height: 1.6rem;
    width: 30rem;
    height: 50vh;
    min-height: 350px;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    resize: none;
}

.event__text__box__eng textarea {
    text-align: left;
}

.event__text__box__p {
    font-family: $font;
    font-weight: $fontRegular;
    display: inline-block;
    text-align: right;
    color: $gray;
    font-size: 1.6rem;
    line-height: 1.6rem;
    width: 30rem;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}

.event__text__box__p__eng {
    text-align: left;
}


.events__event__select__image__icon {
    width: 5rem;
    align-self: center;
}



.events__event__select__image__button__div {
    position: absolute;
    background-color: rgba(255, 176, 161, 0.2);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    -webkit-transition: all $transitionTime ease-in; /* Safari */
    transition: all $transitionTime ease-in;
}

.events__event__select__image__button__div:hover {
    opacity: 1;
}


.events__event__select__image__button__div button {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    background: none;
    border: none;
}


.events__eventshare__nav{
    display: flex;
    justify-content: center;
    align-items: center;
}






$eventsEventWidthTablet: 20.55rem;
$eventsEventHeightTablet: 17.5rem;
$eventsEventImageHeightTablet: 14.2rem;
@media (max-width: $tabletMaxWidth) {
    .event__header__box {
        width: 72rem;
    }

    .events__event__box {
        width: $eventsEventWidthTablet;
    }

    .events__event__image {
        width: $eventsEventWidthTablet;
    }

    .events__event__title {
        width: $eventsEventWidthTablet;
    }

    .events__event__add__button {
        width: $eventsEventWidthTablet;
    }


    .events__event__button__box {
        width: $eventsEventWidthTablet;
    }

    .events__event__button {
        width: $eventsEventWidthTablet;
    }

    .events__event__button__text__div {
        width: $eventsEventWidthTablet;
    }
}



$eventsEventWidthMobile: 100%;
$eventsEventHeightMobile: 100%;
$eventsEventImageHeightMobile: 92%;


@media (max-width: $mobileMaxWidth) {

    .event__header__image {
        width: 90vw;
    }

    .event__header {
        font-size: 3rem;
        padding: 0;
        margin-top: -1rem;
    }

    .events__eventshare__button__text {
        font-size: 2rem;
    }

    .event__navigation__box {
        width: 70vw;
        display: block;
        
    }

    .event__header__content__box {
        width: 100%;
    }

    .events__eventshare__share{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .events__eventshare__nav{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 4rem;
    }

    .events__eventshare__button__box {
        float: none;
        margin-left: 1rem;
        margin-right: 1rem;
        width: 16rem;
    }

    .events__eventshare__button__image {
        width: 4rem;
        margin-top: 1.2rem;
    }

    .events__eventshare__button__image__x {
        width: 2.5rem;
        padding-top: 1.1rem;
        margin-left: 1rem;
    }

    .event__sharestrip__box {
        width: 40rem;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .event__header__box {
        width: 100%;
        flex-direction: column;
        padding-top: 1rem;
    }

    .event__header__box input {
        margin: 0;
        padding: 0;
    }


    .events__event__box {
        position: relative;
        width: $eventsEventWidthMobile;
        margin-top: 2.2rem;
        margin-left: 1.1rem;
        margin-right: 0rem;
        margin-left: auto;
        margin-right: auto;
    }

    .events__event__image__box {
        padding-top: 6vw;
        width: $eventsEventWidthMobile;
        height: $eventsEventImageHeightMobile;
    }

    .events__event__image {
        width: $eventsEventWidthMobile;
        height: $eventsEventImageHeightMobile;
    }

    .events__event__title {
        width: $eventsEventWidthMobile;
        font-size: 2.8rem;
        padding-top: 0.8rem;
        height: 5rem;
    }

    .events__event__add__button {
        width: $eventsEventWidthMobile;
        height: $eventsEventHeightMobile;
    }

    .events__event__button__box {
        width: $eventsEventWidthMobile;
        height: $eventsEventHeightMobile;
        margin-left: auto;
        margin-right: auto;
    }

    .events__event__button {
        width: $eventsEventWidthMobile;
        height: $eventsEventHeightMobile;
    }

    .events__event__button__text {
        font-size: 3rem;
        margin: 0;
        padding: 0;
    }

    .events__event__button__text__div {
        opacity: 0;
        top: 0;
    }

    .events__event__button__box button {
        opacity: 0;
        top: 0;
    }

    .events__event__button__box:hover button {
        opacity: 0;
        top: 0;
    }

    .events__event__button__box div {
        opacity: 0;
        top: 0;
    }

    .events__event__button__box:hover div {
        opacity: 0;
        top: 0;
    }

    .event__text__box {
        width: 90vw;
        margin-left: 0;
        height: auto;
        margin-bottom: 3rem;
    }

    .event__text__box input {
        font-family: $font;
        font-weight: $fontRegular;
        position: absolute;
        width: 5rem;
        height: 2rem;
        z-index: 5;
        left: -10%;
        top: 4%;
    }

    .event__text__box textarea {
        font-size: 2.2rem;
        line-height: 2.2rem;
        width: 90vw;
        min-height: 40px;
    }

    .event__text__box__p {
        font-size: 2.2rem;
        line-height: 2.2rem;
        width: 90vw;
    }

    .rah-static--height-specific {
        height: auto !important;
    }

    .events__eventshare__text {
        font-size: 2.5rem;
        line-height: 2.3rem;
        margin-bottom: 0.5rem;
        display: inline-block;
        width: auto;
    }

    $iconWidth: 4.7rem;
    $iconHeight: 4.7rem;
    $iconMarginTop: 0.4rem;
    $iconMarginBottom: 0.6rem;

    .events__eventshare__phone__mobile {
        width: $iconWidth;
        height: $iconHeight;
        background-image: url('/images/eventspage/events-sharestrip-phone.svg');
        background-repeat: no-repeat;
        background-size: $iconWidth $iconHeight;
        margin-top: $iconMarginTop;
        margin-bottom: $iconMarginBottom;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        display: inline-block;
    }

    .events__eventshare__mail__mobile {
        width: $iconWidth;
        height: $iconHeight;
        background-image: url('/images/eventspage/events-sharestrip-mail.svg');
        background-repeat: no-repeat;
        background-size: $iconWidth $iconHeight;
        margin-top: $iconMarginTop;
        margin-bottom: $iconMarginBottom;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        display: inline-block;
    }

    .events__eventshare__facebook__mobile {
        width: $iconWidth;
        height: $iconHeight;
        background-image: url('/images/eventspage/events-sharestrip-facebook.svg');
        background-repeat: no-repeat;
        background-size: $iconWidth $iconHeight;
        margin-top: $iconMarginTop;
        margin-bottom: $iconMarginBottom;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        display: inline-block;
    }

    .events__events__oneline__header__box {
        text-align: center;
        width: 100%;
        padding-right: 0rem;
    }

    .events__events__oneline__header {
        font-size: 3rem;
        padding-top: 1rem;
    }

    .events__events__oneline__more {
        padding-top: 0.6rem;
        padding-bottom: 0.5rem;
        font-size: 2.5rem;
    }

    .events__event__box--event {
        margin-top: 0.3rem;
    }
}