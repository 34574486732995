// Colors
$white:  #fff;
$offwhite: #f9f9f9;
$gray: #6c7680;
$green: #749260;
$yellow: #e9de13;
$red: #cc7f6b;
//$lightblue: rgba(113,198,236,0.5);
//$lightblueDror: #2c95c0;
//$lighblueZiva: #3f67a7;
$lightblue: #3f67a7;
$blue: #2c95c0;
$darkblue: #2993bd;

//fonts
$font: 'Rubik', sans-serif;

//font-size
$fontRegular: 400;
$fontMedium: 500;
$fontBold: 700;
 

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;

//time
$buttonScaleTime: 40ms;
$transitionTime: 0.2s;

// media sizes
$mobileMaxWidth: 767px;
$tabletMaxWidth: 949px;