.pagenotfound__structure {
    display: flex;
    justify-content: center;
    padding-top: 3.4rem;
    background: rgba(66,66,66,0.09%);
}

.pagenotfound__box {
    width: 90rem;
    text-align: center;
}

.pagenotfound__image {
    display: block;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.pagenotfound__header__image {
    display: block;
    width: 20vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.8rem;
}

.pagenotfound__gray__bg {
    width: 100%;
    height: 11rem;
    position: absolute;
    background: rgba(66,66,66,0.09%);
    z-index: -1;
    left: 0;
    top: 0;
}


.pagenotfound__goback {
    width: 29.6rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left:auto;
    margin-right: auto;
    margin-bottom: 4rem;
}

.pagenotfound__goback__button {
    display: flex;
    justify-content: center;
    align-content: center;
    background: none;
    border: none;
    position: relative;
    margin-left:auto;
    margin-right: auto;
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1);
    transition: all 20ms ease-in;
    transform: scale(1);   
}

.pagenotfound__goback__button:hover {
    text-decoration: none;
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1.2);
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1.2);   
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1.2);
    transition: all 20ms ease-in;
    transform: scale(1.2);
}

.pagenotfound__goback__button__image {
    width: 1.95rem;
    padding-top: 0.7rem;
}

.pagenotfound__goback__button__superheader {
    margin: 0;
    padding: 0;
    font-size: 18rem;
    margin-left: 0.3rem;
    padding-top: 6rem;
    color: $lightblue;
}

.pagenotfound__goback__button__header {
    margin: 0;
    padding: 0;
    font-size: 5rem;
    margin-left: 0.3rem;
    padding-top: 0rem;
    color: $lightblue;
}

.pagenotfound__goback__button__text {
    margin: 0;
    padding: 0;
    font-size: 1.75rem;
    margin-left: 0.3rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    color: $lightblue;
}

@media (max-width: $mobileMaxWidth) {

    .pagenotfound__box {
        width: 100vw;
    }

    .pagenotfound__gray__bg {
        height: 8rem;
    }

    .pagenotfound__image {
        width: 80vw;
        margin-top: 3rem;
        margin-bottom: 4rem;
    }

    .pagenotfound__header__image {
        width: 65vw;
        margin-bottom: 1.6rem;
    }

    .pagenotfound__goback__button__image {
        width: 4rem;
        padding-top: 1.2rem;
    }

    .pagenotfound__goback__button__text {
        font-size: 3.7rem;
    }

}