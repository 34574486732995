.contactstrip__box {
    background: $lightblue;
    display: flex;
    justify-content: center;
    padding-left: 10rem;
    padding-right: 0rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.contactstrip__box__eng {
    padding-left: 0rem;
    padding-right: 10rem;
}

.map__helper {
    position: relative !important;
    width: 20vw !important;
    height: 20vw !important;
}

.contact__form__header--strip {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding-bottom: 0.2rem;
    font-size: 2.3rem;
    color: $white;
    border-bottom: 1px solid $white;
}

.contact__form__box--strip {
    display: inline-block;
    text-align: right;
}

.contact__form--strip {
    display: flex;
    justify-content: center;
    margin-left: 0vw;
    margin-right: 6vw;
}

.contact__form__eng--strip {
    margin-left: 6vw;
    margin-right: 0vw;
}

.contact__error--strip {
    font-size: 1.45rem;
    color: $white;
}

.contact__form--strip input { 
    display: block;
    font-size: 1.45rem;
    color: $darkblue;
    width: 25rem;
    height: 3.4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    font-family: $font;
    font-weight: $fontRegular;
    border: 1px solid $darkblue;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
}

.contact__form__eng--strip input[type="email"] {
    text-align: left;
}
.contact__form__eng--strip input[type="email"]:-moz-placeholder {
    text-align: left;
}
.contact__form__eng--strip input[type="email"]:-ms-input-placeholder {
    text-align: left;
}
.contact__form__eng--strip input[type="email"]::-webkit-input-placeholder {
    text-align: left;
}

.contact__form--strip textarea { 
    display: block;
    font-size: 1.45rem;
    color: $darkblue;
    width: 25rem;
    height: 10.8rem;
    padding-right: 1rem;
    padding-left: 1rem;
    font-family: $font;
    font-weight: $fontRegular;
    border: 1px solid $darkblue;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.8rem;
    resize: none;
}

.contact__form--strip button { 
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.contact__imagebutton--strip {
    display: none;
}

.contact__button--strip {
    border: none;
    background: none;
    padding: 0;
    margin-bottom: 0.3rem;
    font-family: $font;
    font-weight: $fontRegular;
    font-size: 1.45rem;
    color: $white;
    align-self: flex-end;
    white-space: nowrap;
    position: relative;
    -webkit-transition: all $buttonScaleTime ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all $buttonScaleTime ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all $buttonScaleTime ease-in;
    -moz-transform: scale(1);
    transition: all $buttonScaleTime ease-in;
    transform: scale(1);   
}

.contact__button--strip:hover
{
    -webkit-transition: all $buttonScaleTime ease-in;
    -webkit-transform: scale(1.15);
    -ms-transition: all $buttonScaleTime ease-in;
    -ms-transform: scale(1.15);   
    -moz-transition: all $buttonScaleTime ease-in;
    -moz-transform: scale(1.15);
    transition: all $buttonScaleTime ease-in;
    transform: scale(1.15);
}

//

.contact__follow__box--strip {
    display: inline-block;
    text-align: center;
    margin-left: 8rem;
    margin-right: 5rem;
}

.contact__follow__header--strip {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding-bottom: 0.2rem;
    font-size: 2.3rem;
    color: $white;
    border-bottom: 1px solid $white;
}

.contact__follow__facebook__icon--strip {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background-image: url('/images/homepage/contactstrip/facebook-icon.png');
    background-repeat: no-repeat;
    background-size: 4rem 4rem;
    margin-top: 1.5rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    cursor: pointer;
}

.contact__follow__facebook__icon--strip:hover {
    background-image: url('/images/homepage/contactstrip/facebook-icon-over.png');
}

.contact__follow__facebook__icon--strip__hover {
    background-image: url('/images/homepage/contactstrip/facebook-icon-over.png');
    position: absolute;
    z-index: 7004;
    opacity: 0;
}

.contact__follow__instagram__icon--strip {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background-image: url('/images/homepage/contactstrip/instagram-icon.png');
    background-repeat: no-repeat;
    background-size: 4rem 4rem;
    margin-top: 1.5rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    cursor: pointer;
}

.contact__follow__instagram__icon--strip:hover {
    background-image: url('/images/homepage/contactstrip/instagram-icon-over.png');
}

.contact__follow__instagram__icon--strip__hover {
    background-image: url('/images/homepage/contactstrip/instagram-icon-over.png');
    position: absolute;
    z-index: 7005;
    opacity: 0;
}

//

.contact__contact__box--strip {
    display: inline-block;
    text-align: right;
}

.contact__contact__box__eng--strip {
    text-align: left;
}

.contact__contact__header--strip {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-top: 3rem;
    margin-bottom: 0.6rem;
    padding-bottom: 0.2rem;
    font-size: 2.3rem;
    color: $white;
    border-bottom: 1px solid $white;
}

.contact__contact__text__line {
    margin: 0;
    padding: 0;
}

.contact__contact__text--strip {
    display: inline-block;
    margin: 0;
    padding: 0;
    color: $white;
    font-size: 1.7rem;
    text-align: right;
    margin-left: 0.6rem;
    margin-top: -0.4rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.contact__seperator--strip {
    width: 12rem;
}




@media (max-width: $mobileMaxWidth) {

    .map__helper {
        height: 80vw !important;
    }

    .contactstrip__box {
        background: $lightblue;
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }

    .contact__contact__box--strip {
        display: inline-block;
        text-align: center;
        padding-top: 1rem;
    }

    .contact__contact__header--strip {
        display: inline-block;
        margin: 0;
        padding: 0;
        margin-top: 3rem;
        margin-bottom: 1rem;
        padding-bottom: 0.2rem;
        font-size: 5.3rem;
        color: $white;
        border-bottom: 1px solid $white;
    }

    .contact__contact__text__line--strip {
        margin-top: 1.2rem;
    }
    .contact__contact__text--strip {
        display: block;
        margin: 0;
        padding: 0;
        color: $white;
        font-size: 3.4rem;
        text-align: center;
        margin-top: -1rem;
    }

    .contact__seperator--strip {
        width: 12rem;
    }





    //

    .contact__follow__box--strip {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .contact__follow__header--strip {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        padding-bottom: 0.2rem;
        font-size: 5.3rem;
    }

    $contactFollowIcon: 8.2rem;

    .contact__follow__facebook__icon--strip {
        display: inline-block;
        width: $contactFollowIcon;
        height: $contactFollowIcon;
        background-image: url('/images/homepage/contactstrip/facebook-icon.png');
        background-repeat: no-repeat;
        background-size: $contactFollowIcon $contactFollowIcon;
        margin-top: 1.5rem;
        margin-left: 1.1rem;
        margin-right: 1.1rem;
    }

    .contact__follow__instagram__icon--strip {
        display: inline-block;
        width: $contactFollowIcon;
        height: $contactFollowIcon;
        background-image: url('/images/homepage/contactstrip/instagram-icon.png');
        background-repeat: no-repeat;
        background-size: $contactFollowIcon $contactFollowIcon;
        margin-top: 1.5rem;
        margin-left: 1.1rem;
        margin-right: 1.1rem;
    }

    //



    .contact__form__header--strip {
        display: inline-block;
        margin: 0;
        padding: 0;
        margin-top: 3rem;
        margin-bottom: 2rem;
        padding-bottom: 0.2rem;
        font-size: 5.3rem;
        color: $white;
        border-bottom: 2px solid $white;
    }

    .contact__form__box--strip {
        display: block;
        text-align: center;
    }

    .contact__form--strip {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 0vw;
        margin-right: 0vw;
        margin-top: 1.5rem;
    }

    .contact__error--strip {
        font-size: 3rem;
        color: $white;
    }

    .contact__form--strip input { 
        display: block;
        font-size: 3.2rem;
        color: $gray;
        width: 92vw;
        height: 6.8rem;
        padding-right: 1rem;
        font-family: $font;
        font-weight: $fontRegular;
        border: 1px solid $gray;
        margin-left: 4vw;
        margin-right: 4vw;
        margin-bottom: 0.6rem;
    }

    .contact__form--strip textarea { 
        display: block;
        font-size: 3.2rem;
        color: $gray;
        width: 92vw;
        height: 30rem;
        padding-right: 1rem;
        font-family: $font;
        font-weight: $fontRegular;
        border: 1px solid $gray;
        margin-left: 4vw;
        margin-right: 4vw;
        margin-bottom: 0.8rem;
    }

    .contact__imagebutton--strip {
        display: none;
    }

    .contact__button--strip {
        width: 92vw;
        height: 7rem;
        font-size: 3.5rem;
        color: $white;
        background: $yellow;
        border: none;
        position: relative;
        margin-bottom: 3.5rem;
    }

    .contact__form--strip button { 
        margin-left: 4vw;
        margin-right: 4vw;
    }
}