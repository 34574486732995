html {
    font-size: 50%;
}

@media (min-width: 768px) {
    html {
        font-size: 48%;
    }
}

@media (min-width: 800px) {
    html {
        font-size: 52%;
    }
}

@media (min-width: 850px) {
    html {
        font-size: 52%;
    }
}

@media (min-width: 950px) {
    html {
        font-size: 57%;
    }
}

@media (min-width: 1100px) {
    html {
        font-size: 62%;
    }
}

@media (min-width: 1440px) {
    html {
        font-size: 67%;
    }
}

@media (min-width: 1650px) {
    html {
        font-size: 72%;
    }
}

@media (min-width: 1750px) {
    html {
        font-size: 75%;
    }
}

@media (min-width: 1850px) {
    html {
        font-size: 80%;
    }
}

@media (min-width: 1950px) {
    html {
        font-size: 83%;
    }
}

@media (min-width: 2050px) {
    html {
        font-size: 87%;
    }
}

@media (min-width: 2200px) {
    html {
        font-size: 92%;
    }
}

@media (min-width: 2300px) {
    html {
        font-size: 100%;
    }
}

@media (min-width: 2400px) {
    html {
        font-size: 105%;
    }
}

body {
    font-family: $font;
    font-size: $m-size;
    background-color: $white;
}

h3 {
    padding: 0;
    margin: 0;
}

button {
    cursor: pointer;
}

button:focus {
    outline: 0;
}

textarea {
  overflow: hidden; 
}

textarea:focus {
    outline:0;
}

button:disabled {
    cursor: default;
}

.newSubcategoryModal {
    _text-align: right;
}

.fade:not(.show) {
    opacity: 100;
}

.modal-content {
    top: 22rem;
}

.modal-backdrop {
    background-color:rgba(86,87,89,0.79);
}

.modalHeader {
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #e9ecef;
}

.modalHeader .close {
    padding: 1rem;
    float: left;
}

.modalTitle {
    text-align: right;
    padding: 1rem;
}

.modalBody {
    text-align: right;
    padding: 1rem;
}


.container-fluid {
    margin: 0;
    padding: 0;
}

.first-top-margin {
    margin-top: 3.5rem;
}

.demo__image {
    width: 100%;
}

.border {
    border: 1px solid black;
}

.align-text-center {
    text-align: center;
}

.inline-block {
    display: inline-block;
}



.edit__panel {
    position: fixed;
    z-index: 505;
    left: 5rem;
    top: 20rem;
}

.edit__changed__bg {
    background: rgba(128,0,32,0.2);
}

.edit__bg {
    background: none;
}


.Heebo-Regular {
  font-family: $font;
  font-weight: $fontRegular;
}

.Heebo-Medium {
  font-family: $font;
  font-weight: $fontMedium;
}

@media (max-width: $mobileMaxWidth) {
    .Heebo-Medium-Mobile {
        font-family: $font;
        font-weight: $fontMedium;
    }
}

.Heebo-Bold {
  font-family: $font;
  font-weight: $fontBold;
}

.almoni-tzar-aaa-regular {
  font-family: almoni-tzar-aaa-regular, sans-serif;
}