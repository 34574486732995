.workshop__structure {
    display: flex;
    justify-content: center;
    padding-top: 0rem;
}


.workshop__topstrip__box {
    display: flex;
    justify-content: center;
    width: 99vw;
    height: 35rem;
    margin-bottom: 8rem;
}



.workshop__topstrip__image {
    width: 58rem;
    height: 38.5rem;
}

.workshop__topstrip__slogen {
    width: 40vw;
    margin-top: 30rem;
    padding-right: 3rem;
    padding-left: 3rem;
    color: $gray;
    font-family: $font;
    font-weight: $fontMedium;
    font-size: 2rem;
    text-align: right;
    border: none;
    resize: none;
}

.workshop__topstrip__slogen__eng {
    font-size: 1.8rem;
    text-align: left;
}



.workshop__topstrip__header {
    width: 14.3rem;
    position: absolute;
    z-index: 3;
    margin-top: 16rem;
    margin-left: 14.3rem;
}

.workshop__topstrip__text {
    color: $gray;
    font-size: 3.1rem;
    position: absolute;
    z-index: 4;
    margin-top: 29.3rem;
    margin-left: 6.7rem;
}

.workshop__left {
    width: 99vw;
}

.workshop__gallery {
    position: relative;
    width: 80rem;
    margin-left: auto;
    margin-right: auto;
}

.workshop__content__box {
    vertical-align: top;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0.7rem;
}




.workshop__content__header__box {
    display: inline-block;
    text-align: right;
    line-height: 2rem;
}

.workshop__content__header__in__box {
    display: inline-block;
    text-align: right;
}

.workshop__content__header {
    color: $gray;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-align: right;
    width: 100vw;
    padding-top: 2rem;
    padding-left: 20vw;
    padding-right: 20vw;
    font-size: 1.9rem;
    border: none;
    resize: none;
    height: auto;
}

.workshop__content__header__eng {
    text-align: left;
}

.workshop__content__header__border {
    margin: 0;
    padding: 0;
    margin-top: -1.6rem;
}

.workshop__content__text__box {
    position: relative;
    margin: 0;
    padding: 0;
    width: 75rem;
}

.workshop__content__text {
    color: $gray;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: right;
    font-size: 1.8rem;
    width: 60vw;
    line-height: 2.2rem;
    border: none;
    resize: none;
    margin-left: 20vw;
    margin-right: 20vw;
    padding-bottom: 2rem;
}

.workshop__content__text__eng {
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-align: left;
}

.workshop__content__button__box {
    position: relative;
    margin: 0;
    padding: 0;
    width: 46rem;
}

.workshop__content__button {
    position: absolute;
    z-index: 5;
    margin-top: -2.5rem;
    left: 0.3rem;
    background: none;
    border: none;
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1);
    transition: all 20ms ease-in;
    transform: scale(1);   
}

.workshop__content__button:hover
{
    -webkit-transition: all 20ms ease-in;
    -webkit-transform: scale(1.2);
    -ms-transition: all 20ms ease-in;
    -ms-transform: scale(1.2);   
    -moz-transition: all 20ms ease-in;
    -moz-transform: scale(1.2);
    transition: all 20ms ease-in;
    transform: scale(1.2);
}

.workshop__content__button__image {
    width: 1.65rem;
    margin-bottom: -0.5rem;
}

.workshop__content__footer {
    color: $gray;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: right;
    font-size: 1.5rem;
    width: 46rem;
}

.workshop__content__images {
    _border: 1px solid black;
    text-align: center;
    width: 90rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
}



.workshop__content__image {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
    height: 23.6rem;
    width: auto;
}


.workshop__content__box input {
    font-family: $font;
    font-weight: $fontRegular;
    width: 5rem;
    height: 2rem;
    position: absolute;
    z-index: 5;
    left: 18%;
}







@media (max-width: $tabletMaxWidth) {
    .workshop__topstrip__box {
        width: 72rem;
        height: 45rem;
    }

    .workshop__left {
        width: 72rem;
    }


    .workshop__content__box {
        width: 72rem;
    }

    .workshop__content__images {
        width: 72rem;
    }

    .workshop__topstrip__bg {
        width: 47rem;
        height: auto;
    }
}










@media (max-width: $mobileMaxWidth) {

    .workshop__topstrip__slogen {
        width: 90vw;
        margin-top: 3rem;
        padding-right: 3rem;
        padding-left: 3rem;
        font-size: 2.6rem;
        text-align: right;
    }

    .workshop__topstrip__slogen__eng {
        font-size: 2.4rem;
        text-align: left;
    }

    .workshop__gallery {
        width: 100%;
        margin: 0;
        margin-bottom: 1rem;
    }

    .workshop__topstrip__box {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: auto;
        margin-bottom: 2.3rem;
        text-align: center;
    }

    .workshop__topstrip__gray__bg {
        height: 51rem;
    }


    .workshop__topstrip__header {
        width: 14.3rem;
        position: relative;
        display: block;
        margin-top: 0.5rem;
    }

    .workshop__topstrip__text {
        color: $gray;
        font-size: 4.5rem;
        position: relative;
        display: block;
        margin-top: -0.5rem;
        margin-left: -2rem;
    }

    .workshop__topstrip__bg {
        width: 92%;
        height: auto;
        position: relative;
        margin-left: 4%;
        margin-bottom: 1rem;
    }

    .workshop__left {
        width: 100vw;
    }

    .workshop__content__box {
        width: 100vw;
        vertical-align: top;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
    }

    .workshop__content__images {
        text-align: center;
        width: 100vw;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .workshop__content__image {
        padding: 0;
        margin: 0;
        margin-bottom: 0.5rem;
        height: auto;
        width: 94%;
    }

    .workshop__content__header__box {
        text-align: center;
        line-height: 2rem;
        width: 98%;
        margin-left: 0;
    }

    .workshop__content__header__in__box {
        text-align: center;
        max-width: 98%;
    }

    .workshop__content__header {
        text-align: right;
        width: 90vw;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 1rem;
        font-size: 3.4rem;
        line-height: 2.9rem;
    }

    .workshop__content__header__eng {
        text-align: left;
    }

    .workshop__content__text__box {
        text-align: center;
        width: 94vw;
    }

    .workshop__content__text {
        text-align: right;
        margin-left: 0;
        margin-right: 0;
        padding-left: 3rem;
        padding-right: 3rem;
        width: 90vw;
        font-size: 2.6rem;
        line-height: 2.6rem;
        margin-bottom: 2rem;
    }

    .workshop__content__text__eng {
        font-size: 2.4rem;
        line-height: 2.4rem;
        text-align: left;
    }


    .workshop__content__button__box {
        text-align: center;
        width: 94vw;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .workshop__content__button {
        height: 3.8rem;
        position: relative;  
    }

    .workshop__content__button__image {
        width: 6rem;
    }

    .workshop__content__footer {
        text-align: center;
        font-size: 3.1rem;
        line-height: 1;
        width: 22rem;
    }
}